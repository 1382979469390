import React from 'react';
import { Link } from "gatsby"
import FacebookBadge from '../../components/FacebookBadge';
import Layout from '../../components/layout';
import SEO from "../../components/seo"
import { Helmet } from "react-helmet"
import Grid from '@material-ui/core/Grid';
import { faClock, faUser, faAngleRight} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './articles.css';

//Import At least four Images for the Article
import articleImage_1 from '../../images/services/Savente-Consulting-Limited-Services-01.jpg';
import articleImage_2 from '../../images/social-media-images/SL-120219-25650-11 [Converted]-03.jpg';
import articleImage_3 from '../../images/social-media-images/SL-120219-25650-11 [Converted]-01.jpg';
import articleImage_4 from '../../images/social-media-images/SL-120219-25650-11 [Converted]-04.jpg';

const articleDetails = {
         title: "Partnerships and Collaborations: The Right Alliances for Clinical Trials in Africa",
         date: "14th March, 2021",
         datePublished: "2021-03-14T08:00:00+08:00", // Y-M-d
         dateModified: "2021-03-14T08:00:00+08:00", //
         author: "Savante Consulting Limited",
         supurb: "A mandatory requirement for clinical research organizations in Nigeria and other regions, to register clinical trials in international, national, or regional platforms will also increase proﬁciency and transparency"
    }
;

const images = [
    {
      image: articleImage_1,
      alt: 'Face Painting',
      key:1,
    },
    {
        image: articleImage_2,
        alt: 'Face Painting',
        key:2,
    },
    {
        image: articleImage_3,
        alt: 'Face Painting',
        key:3,
    },
    {
        image: articleImage_4,
        alt: 'Face Painting',
        key:4,
    },
  ];

const listItems = images.map((image) => <div key={image.key}  className="col s12 l3 hide-on-small-only">
        <img src={image.image} className="responsive-img" alt={image.alt} />
        </div>);

export default function Partnerships(){

    return(
        <Layout>
            <article>
            <SEO 
                title={articleDetails.title} 
                description={articleDetails.supurb}
                image={articleImage_1}

            />
            <Helmet>
            <script type="application/ld+json">
                {`                    
                      {
                        "@context": "https://schema.org",
                        "@type": "NewsArticle",
                        "headline": "`+articleDetails.title+`",
                        "image": [
                          "`+articleImage_1+`",
                          "`+articleImage_2+`",
                          "`+articleImage_3+`",
                          "`+articleImage_4+`",
                         ],
                        "datePublished": "`+articleDetails.datePublished+`",
                        "dateModified": "`+articleDetails.dateModified+`"
                      }
                  
                  
                `}
            </script>
            </Helmet>
            <br />
            <br />
            <div className="container article">


                <h1 className="title secondary-color-dark-text mb-5">{articleDetails.title}</h1>     
                <Grid container>
                            <Grid  item xs={12}>
                                <time className="pr-10">
                                    <FontAwesomeIcon className="mr-8" style={{width: '1.125em'}} aria-label="Mute volume" icon={faClock} />
                                    <span>{articleDetails.date}</span>
                                </time>
                                <time>
                                    <FontAwesomeIcon className="mr-8" style={{width: '1.125em'}} aria-label="Mute volume" icon={faUser} />
                                    <span>Olusola Solarin, MBA; Sulma I. Mohammed, DVM, PhD; Ntokozo Ndlovu, MMed; Verna Vanderpuye, MBChB; and Victoria Olaiya, BSc, BDS</span>
                                </time>
                            </Grid>
                </Grid>
                <div className="row">
                    <div className="col s12 l8 article-body">

                        <Link to="/contact-us" className="btn btn-small waves-effect waves-light  getqouteButton hide-on-large-only  mb-10 mt-10" style={{width: '100%'}}>Contact Us</Link>   

                        <div class="justify">
                            <p>Africa attracts,1% of all trials conducted around the world. The implication is that proof of safety and efﬁcacy in Africans is lacking for a lot of new therapies. The sizeable proportion of approximately 20% of the global population that Africa represents largely does not have empiric data to support use of new therapies in a population with a distinct genetic and racial proﬁle. Beyond the imperative of evidence-based interventions, Africans carry a disproportionately heavy burden of certain diseases, including prostate cancer, sickle cell anemia, and malaria. It therefore provides opportunity for efﬁcient recruitment of participants for trials for such diseases. However, this advantage has not convinced sponsors to carry out clinical trials in Africa. India and China each have roughly the same population size as Africa, but each presents just one regulatory jurisdiction for clinical trials. Africa has 54 countries, and a sponsor would theoretically need to ﬁle 54 different applications to cover the entire continent. Collaboration and partnership among all stakeholders in the clinical trial ecosystem will reduce the burden on sponsors and make Africa competitive as a destination for clinical trials. Collaboration among national regulatory agencies will enable Africa to be treated as one regulatory jurisdiction and reduce administrative burden. Sites and researchers can partner to improve quality, attain necessary certiﬁcations, and increase overall efﬁciency. Central to all of these are <em>clinical research organizations</em> that can coordinate and work across borders to make clinical trial projects seamless. Ultimately, patients will beneﬁt as quality of clinical practice improves and access to new therapies is enhanced.</p>
                            <p>JCO Global Oncol. &copy; 2020 by American Society of Clinical Oncology</p>
                            <p>Licensed under the Creative Commons Attribution 4.0 License </p>

                            <p>INTRODUCTION</p>
                            <p>Africa is host to.18% of the world&rsquo;s population and 3% of the global pharmaceutical market, but it attracts</p>
                            <p>1% &nbsp;of &nbsp;registered &nbsp;clinical &nbsp;trials. &nbsp;<sup>1</sup> &nbsp;Despite &nbsp;the &nbsp;ad- vantage Africa offers with its unique and diverse ge- netic pool and ease of recruiting trial participants in pathologies like sickle cell anemia, prostate cancer, and some infectious diseases, Africa is still dispro- portionately poorly represented in clinical research. In this article, we will examine reasons for this. We argue that collaboration among stakeholders in the clinical trial ecosystem can improve the attractiveness of Africa as a clinical trial destination for sponsors. Regulatory agencies, researchers/clinicians, research sites, and sponsors can play key roles in improving the ease of conducting trials in Africa.</p>
                            <p>54 COUNTRIES, 54 MARKETS, 54 REGULATORY JURISDICTIONS</p>
                            <p>Each of Africa&rsquo;s 54 countries represents a regulatory jurisdiction for clinical trials and market for health care products. Each of these countries competes for clinical trials with India and China, each of which has roughly</p>
                            <p>The population as Africa but offers sponsors one reg- ulatory jurisdiction and, in the case of China, a much bigger market. Sponsors are likely to ﬁnd it easier to deal with a single regulatory agency, as in each of these two countries, than with the 54 that Africa presents.</p>
                            <p>Also complicating the bureaucratic challenge of conducting trials in Africa is the fact that national regulatory agencies are at different maturity levels.<sup>2</sup> Therefore, the case for partnership and collaboration to support clinical trials in Africa is a compelling one. Collaboration among Africa&rsquo;s national regulatory agencies (NRAs) for medicines could improve com- petitiveness for clinical trials, although planning and implementation of this remain challenging.</p>
                            <p>COLLABORATION AMONG RESEARCHERS IN AFRICA</p>
                            <p>Aided by advances in information-sharing technolo- gies and social media platforms, such as ResearchGate,<sup>3</sup> collaborative research is already in practice and is evidenced by the rapid growth in the number of authors in a publication. Health research assumed collectively by more than one researcher/clinician</p>
                            <p>within the same department, or across academic de- partments in an institution or in different academic in- stitutions or industries, has also become more common across countries. It provides opportunity for collaborators to share expertise and resources and expand their skills and worldviews on health issues. It may yield improved and unique data that can be used to design speciﬁc drugs and targeted medical interventions.</p>
                            <p>Successful collaboration is built on trust, understanding, and selﬂessness. It entails putting the interests of the team and participants ﬁrst. It provides an opportunity for putting expertise and resources together to advance medical knowledge for appropriate intervention.</p>
                            <p>Global collaborative health research is essential to addressing complex diseases, such as cancer, a growing problem in Africa. It requires multidisciplinary expertise. Collabora- tion among social workers, physicians, nurses, scientists, and industries has proven to be essential in conducting successful clinical research. This collaboration beneﬁts not only the patients and the science behind treatment, but also individual researchers mentoring younger ones to develop scholarly attributes. Cross-cultural collaboration promotes healthy interaction among scientists from dif- ferent countries, ensuring scientists and oncologists gain a broader worldview of the burden of cancer, social in- terplay, and management. It goes without saying that ethically based collaboration can yield better scientiﬁc data, increase the capacity for research, improve funding, em- power partners, expose collaborators from different countries to different cancer biologies, and contribute to the personal and professional development of all involved.</p>
                            <p>Despite its many beneﬁts, collaborative research may have setbacks. The heterogeneity of the sociocultural back- grounds of scientists involved, resources (time and money) contributed, professional reputations of researchers, and attributions of credit (authorship) may be sources of tension.<sup>4</sup> A culturally unﬁt and ill-conceived collaboration can destroy trust and breed hostility toward future collaborations.</p>
                           
                            <p>COLLABORATION AMONG SITES TO HARMONIZE TREATMENT GUIDELINES AND BOOST EFFICIENCY</p>
                            <p>Various models of collaborative partnerships between sites within a country or region or between regions have their own merits and pitfalls. Advantages include addressing of common areas of interest, transfer of knowledge and skills, local mentorship of young investigators, ease of recruitment of trial participants, provision of a wider range of clinical judgments, and provision of local evidence for the sub- sequent generalization of trial ﬁndings.</p>
                            <p>For many African clinical trials, the scenario is usually that of a single or multiple sites collaborating with an in- ternational site. Collaboration among African sites seems to be rare. Many reasons have been postulated for this, in- cluding lack of funding and limited research capacity. High-income countries ﬁll the gap by conceptualizing, coordinating, and funding African studies, and the resultant effect at times is the promotion of trials that are not of critical importance to Africa&rsquo;s health care problems.<sup>5-7</sup></p>
                            <p>Most African countries have similar challenges in cancer management, and there is a dearth of local evidence-based solutions and approaches. More collaborations among African sites should be encouraged to facilitate the de- velopment of local treatment guidelines and comprehen- sive national cancer control programs. Collaboration among sites provides a platform for plugging existing gaps in an efﬁcient and timesaving manner and expediting the gen- eration of extensive data. This was successfully demon- strated in a study performed in one African country among 12 sites in one region. The collected data included trends in HIV prevalence, HIV incidence, HIV-associated and all- cause mortality, sexual risk behaviors, and health-seeking behaviors. The investigators were able to measure the ef- fects of prevention and treatment programs on the HIV epidemic and generalize them to the whole country.<sup>8</sup> In another publication, a review of collaboration between HIV care centers and cancer centers was carried out. The ﬁndings demonstrated the merits of integrating cancer research with established HIV programs to obtain timely</p>
                           
                            <p>data about the incidence and burden of cancer in HIV- infected persons in Africa.<sup>9</sup></p>
                            <p>Collaboration among sites also generates problem-solving solutions through standardization and adherence to case deﬁnitions, clinical procedures, laboratory methods, and specimen collection techniques.<sup>10</sup> Broader collaboration corrects for the differences in practice norms that are in- evitable across countries and continents. Addressing and deﬁning the standard of care at various trial sites are im- portant. Study data are credible only if the quality of col- lection and processing of data are standardized across sites, especially in resource-constrained situations. Chal- lenges such as the lack of qualiﬁed personnel and expe- rience in designing software tools and complex databases are common in Africa. These challenges can be effectively resolved if identiﬁed earlier on in the design of studies.<sup>11,12</sup> The recognition of potential harm in sharing data among sites has been explored. One study explored the possibility of the use of genomic data in ways that could cause perceived harm to ethnic groups involved in studies. The potential for abuse may arise where there is sharing of genomic data outside the original group collaborating. Trust is important for such collaborations to thrive, and safe- guarding against such misuse is important.<sup>13</sup></p>
                            <p>Recognition and integration of work culture are important for successful collaboration across sites, especially in Africa, where diverse ethnicities make regional as well as international collaborations intriguing.<sup>14</sup> To this effect, in- ternational clinical electives have been shown to be ef- fective in preparing young potential investigators to receive cross-cultural educational and gain research skills to equip them for future collaborative work.<sup>15</sup></p>
                            <p>COLLABORATING TO ASSURE QUALITY OF OUTCOMES &nbsp;ACROSS BORDERS</p>
                            <p>The paucity of clinical trials in Africa compared with other low- and middle-income countries is also blamed on the lack of stringent quality assurance measures and training to execute the processes necessary to maintain quality of outcomes. Compared with high-income countries, many institutions in Africa do not have training modules in clinical trials for frontline health practitioners, resulting in the major activities of the required processes being outsourced to international partners.<sup>16</sup> Many trials involve several inter- lacing disciplines and therefore require that each aspect has the ability to contribute efﬁciently and appropriately. Best practices to improve clinical trials in Africa involve ensuring standardization of processes, starting from ca- pacity building and continuing through liaison with partners conducting high-quality clinical trials and thereby im- proving quality. The best way to ensure standardization and uniformity across the continent is to have processes veriﬁed by universally acclaimed certiﬁcation. This may involve several exchange visits, human resource training, and equipment upgrades. Principal investigators or key players</p>
                            
                            <p>must be encouraged to obtain clinical research associate certiﬁcation, which will include a course on good clinical practice offered by an accredited institution, usually in- volving an examination. Institutions must be certiﬁed to ensure provision and maintenance of required in- frastructure, and this should be an initial focus of en- gagement with international collaboration to develop capacity. Formation of regional consortiums must be en- couraged to improve quality, scale cultural barriers, and identify regional certiﬁcation opportunities.</p>
                            <p>Currently, mandatory courses for certiﬁcation are funda- mentals of clinical trials, human participant safety, and regulatory requirements for medical devices and phar- maceutical products. However, bioethics, health law, and data management are parts of good clinical practice and should also be mandatory, because they are critical to initiation, enrollment, reporting, and outcomes.<sup>17,18</sup> 
                            A mandatory requirement for clinical research organizations in Nigeria and other regions, to register clinical trials in international, national, or regional platforms will also increase proﬁciency and transparency and serves as a form of certiﬁcation to improve standards of conducting clinical trials. Indeed, many will strive for listing in regional or in- ternational
                             registries with international accreditation. This module was instituted in Italy for phase I trials and resulted in increased standards and increased requests to 
                             obtain accreditation to conduct clinical trials in this category.<sup>19</sup> WHO has developed guidelines for clinical trials 
                             registries, which could be embraced as a template by sub-Saharan African institutions.<sup>20,21</sup> A retrospective study from
                              the United States demonstrated improved standards after accredited good practice certiﬁcation in four randomized trials.<sup>22</sup></p>
                            <p>PAYERS, <strong><em><u>CONTRACT RESEARCH ORGANIZATIONS</u></em></strong>, AND &nbsp;&nbsp;PHARMACEUTICAL COMPANIES</p>

                            <p>Unlike many high-income countries, private insurance does not play a dominant role in health care in Africa. Except for South Africa, the main payer is the government. It is prudent to recognize the emerging role of payers in the trajectory of the clinical trials landscape, because they, as with other stakeholders, will enable end-user access to these new interventions. Early collaboration will ensure minimal waste of resources, prioritize needs, formulate research questions, and enhance cost effectiveness of new innovations. This will help reduce ﬁnancial losses and health care burden as a result of adverse effects of new interventions.</p>
                            <p>In recent years, there has been an increase in the number of biopharmaceutical companies using the services of <strong><em><u>contract research organizations</u></em></strong> (CROs) and other clinical trial service providers for the delivery of their research and development operations. In a research and market report published in June 2015, it was mentioned that by 2020, an estimated 72% of all clinical trials will be outsourced to CROs<sup>23</sup>; this is approximately three fourths of clinical trials.</p>
                           
                            <p>A CRO provides research and clinical trial support services to the pharmaceutical, biotechnology, and medical device industries as well as to academic institutions, foundations, government organizations, and hospitals on a contract basis. An individual, company, institution, business, or- ganization, or group of organizations that contracts with a CRO is called a sponsor.</p>
                            <p>Major pharmaceutical companies have a presence in Africa and are in partnerships with local businesses for support in navigating the diverse and heterogeneous market of.50 countries. The pharmaceutical industry is increasingly looking toward emerging markets, including Africa, for drug development and basic research. It is estimated that the pharmaceutical industry is expected to invest $45 billion on the continent by 2020. Countries like Egypt and South Africa are home to 75% of the 8,897 clinical trials in Africa as a result of their comparative economic strength, followed by Tunisia, Kenya, Uganda, Tanzania, Zambia, and Bur- kina Faso, documenting at least 200 trials in the year 2019.<sup>24</sup> These countries are striving for health improve- ments through strategic investments and promotion and facilitation of clinical research.</p>
                            <p>Pharmaceutical companies will often partner with tech- nology organizations and CROs to set up and run their clinical trials. The CROs have access to local knowledge; they work with investigators and local clinical research teams in the identiﬁcation and recruitment of patients, collection and analysis of patient data, and assessment of patient safety as regards adverse effects.<sup>25</sup> There are global CROs with ofﬁces in Africa, and there are also locally established CROs. These organizations often work together in the delivery of clinical trial services.</p>
                            <p>CROs fall into different types. Preclinical CROs, which perform testing of drugs or medical devices before the compounds or devices go on to the human or clinical phase to ensure safety and effectiveness. Clinical CROs specialize in patient recruitment, site selection, monitoring, medical writing, regulatory preparation of applications to ethics committees and regulatory authorities, data management, and other clinical trial services.</p>
                            <p>CROs understand the local climate best suited for business with related economic outcomes for their investors, owners, and shareholders. For a successful CRO-sponsor collab- oration, it is necessary for the sponsor to choose a CRO that will be an effective partner. This collaboration can be both rewarding and rich if it is well established. For the sponsor, outsourcing clinical trials via CROs has the potential to reduce costs and provide access to local knowledge and</p>
                           
                            <p>local expertize (country experts) in various geographic markets, thereby giving sponsor a global reach.</p>
                            <p>The following are keys to a successful CRO-sponsor col- laboration: deﬁning common goals up front, engaging in shared decision making, and establishing clear commu- nication and practical project management plans as well as secure cloud platforms to be used for data sharing by the CRO and sponsor; deﬁning what information is to be communicated during the study, the frequency of com- munications, and which issues require escalation<sup>26</sup>; and establishing the key performance indicators and how performance will be measured against set goals. In addi- tion, an effective two-way communication process should be in place. These practices will create an atmosphere for positive collaboration that is beneﬁcial to the partners and will help to strengthen the CRO-sponsor partnership.</p>
                            <p>The African Union is supporting the creation of an African medicines agency to coordinate the regulation of medicines, including clinical trials all over Africa. In 2006, the African Vaccine Regulatory Forum (AVAREF), a network of NRAs and ethics committees, was established by WHO to strengthen regulation, initially of vaccines, but lately, the platform has been extended to other medicines, and a joint review platform has been developed that sponsors can le- verage to have their applications reviewed simultaneously by AVAREF member countries. It is a platform that has been used to review some clinical trial applications, especially during emergencies, and working documents and guidelines have been developed for harmonization within the continent to enable sponsors to develop a single application package (in case of multinational clinical trials) for review.</p>
                            <p>In conclusion, we believe that collaboration among clini- cians, researchers, sites, and regulatory agencies is es- sential to making Africa an attractive destination for clinical trials. This, alongside quality certiﬁcation of personnel, sites, and process, will win the trust of sponsors and CROs. All these moving parts must run on a framework that will make research seamless and outcomes trusted.</p>
                            <p>Theﬁrst recommendation is for Africa to present just one regulatory jurisdiction to sponsors. The feasibility and ap- plicability of this must be assessed with the diversity of practice within the continent of Africa in mind. Good clinical practice guidelines, training modules in clinical trials, and site checklists must be coordinated, with mutual recogni- tion of inspections by different NRAs to reduce costs and bureaucratic burdens. To ensure standardization and uniformity across the continent, processes must be veriﬁed by universally acclaimed certiﬁcation.</p>
                           

                            <p>AFFILIATIONS</p>
                            <p><sup>1</sup>Savante Consulting, Lagos, Nigeria</p>
                            <p><sup>2</sup>Department of Comparative Pathobiology, Purdue University Center for Cancer Research, West Lafayette, IN</p>
                        
                            <p><sup>3</sup>Department of Radiology, College of Health Sciences, University of Zimbabwe, Harare, Zimbabwe</p>
                            <p><sup>4</sup>Center for Radiotherapy Oncology and Nuclear Medicine, Korle-Bu Teaching Hospital, Accra, Ghana</p>
                            <p><sup>5</sup>Synteract, Ely, Cambridgeshire, United Kingdom</p>
                            
                            <p>CORRESPONDING AUTHOR</p>
                            <p>Olusola Solarin, MBA, Savante Consulting, 6, Broad St, Lagos, Nigeria 101003; Twitter: @SOLA_SOLARIN; e-mail: <a href="mailto:solasolarin@gmail.com">solasolarin@gmail.com.</a></p>
                          
                            <p>AUTHOR CONTRIBUTIONS</p>
                            <p>Conception and design:Olusola Solarin, Ntokozo Ndlovu, Victoria Olaiya</p>
                            <p>Administrative support:Olusola Solarin</p>
                            <p>Collection and assembly of data:Sulma I. Mohammed, Ntokozo Ndlovu, Verna Vanderpuye</p>
                            <p>Data analysis and interpretation:Verna Vanderpuye</p>
                            <p>Manuscript writing:All authors</p>
                            <p>Final approval of manuscript:All authors</p>
                            <p>Accountable for all aspects of the work:All authors</p>
                         
                            <p>AUTHORS&rsquo;DISCLOSURES OF POTENTIAL CONFLICTS OF INTEREST</p>
                            <p>The following represents disclosure information provided by authors of this manuscript. All relationships are considered compensated unless otherwise noted. Relationships are self-held unless noted. I = Immediate Family Member, Inst = My Institution. Relationships may not relate to the subject matter of this manuscript. For more information about ASCO&rsquo;s conﬂict of interest policy, please refer to <a href="http://www.asco.org/rwc">www.asco.org/rwc </a>or ascopubs. org/go/site/misc/authors.html.</p>
                            <p>Open Payments is a public database containing information reported by companies about payments made to US-licensed physicians (Open Payments).</p>
                            <p>No potential conﬂicts of interest were reported.</p>
                
                            <p>REFERENCES</p>
                            <ol>
                            <li>Novotny M: As clinical trials go international, operations go <a rel="noreferrer" target="_blank"  href="http://www.appliedclinicaltrialsonline.com/clinical-trials-go-international-operations-go-">http://www.appliedclinicaltrialsonline.com/clinical-trials-go-international-operations-go-</a> digital</li>
                            <li>Branigan D: WHO benchmarking tool made&ldquo;more transparent&rdquo;in evaluating regulatory https:/<a rel="noreferrer" target="_blank"  href="http://www.healthpolicy-watch.org/who-benchmarking-">/www.healthpolicy-watch.org/who-benchmarking-</a> tool-made-more-transparent-in-evaluating-regulatory-authorities/</li>
                            <li>Wray BK: The epistemic signiﬁcance of collaborative Philos Sci 69:150-168, 2002</li>
                            <li>Chetwood JD, Ladep NG, Taylor-Robinson SD: Research partnerships between high and low-income countries: Are international partnerships always a good thing? BMC Med Ethics 16:36, 2015</li>
                            <li>Swingler GH, Pillay V, Pienaar ED, et al: International collaboration, funding and association with burden of disease in randomized controlled trials in Africa. Bull World Health Organ 83:511-517, 2005</li>
                            <li>Reddy P, Desai R, Sifunda S, et al:&ldquo;You travel faster alone, but further together&rdquo;: Learning from a cross country research collaboration from a British Council Newton Fund grant. Int J Health Policy Manag 7:977-981, 2018</li>
                            <li>Morgan JP, Isyagi M, Ntaganira J, et al: Building oral health research infrastructure: Theﬁrst national oral health survey of Glob Health Action 11: 1477249, 2018</li>
                            <li>Gregson S, Mugurungi O, Eaton J, et al: Documenting and explaining the HIV decline in east Zimbabwe: The Manicaland general population BMJ Open 7:e015898, 2017</li>
                            <li>Mbulaiteye SM, Bhatia K, Adebamowo C, et al: HIV and cancer in Africa: Mutual collaboration between HIV and cancer programs may provide timely research and public health Infect Agent Cancer 6:16, 2011</li>
                            <li>Crawley J, Prosperi C, Baggett HC, et al: Standardization of clinical assessment and sample collection across all PERCH study Clin Infect Dis 64:S228- S237, 2017 (suppl 3)</li>
                            <li>Van den Broeck J, Mackay M, Mpontshane N, et al: Maintaining data integrity in a rural clinical trial. Clin Trials 4:572-582, 2007</li>
                            <li>Forster M, Bailey C, Brinkhof MWG, et al: Electronic medical record systems, data quality and loss to follow-up: Survey of antiretroviral therapy programmes in resource-limited Bull World Health Organ 86:939-947, 2008</li>
                            <li>de Vries J, Williams TN, Bojang K, et al: Knowing who to trust: Exploring the role of&lsquo;ethical metadata&rsquo;in mediating risk of harm in collaborative genomics research in Africa. BMC Med Ethics 15:62, 2014</li>
                            <li>van der Wiel R: Challenging perceptions of disciplinary divide: An ethnographer&rsquo;s experience of collegiality, collaboration and Med Humanit 44:e2, 2018</li>
                            <li>Monroe-Wise A, Kibore M, Kiarie J, et al: The Clinical Education Partnership Initiative: An innovative approach to global health BMC Med Educ 14:1043, 2014</li>
                            <li>Bhatt A: Quality of clinical trials: A moving Perspect Clin Res 2:124-128, 2011</li>
                            <li>Alemayehu C, Mitchell G, Nikles J: Barriers for conducting clinical trials in developing countries: A systematic Int J Equity Health 17:37, 2018</li>
                            <li>Ohmann C, Kuchinke W, Canham S, et al: Standard requirements for GCP: Compliant data management in multinational clinical trials. Trials 12:85, 2011</li>
                            <li>Marchesi E, Monti M, Nanni O, et al: New requirements for phase I trials: A challenge for Italian clinical Tumori 104:15-21, 2018</li>
                            <li>Gu&uml;lmezoglu AM, Pang T, Horton R, et al: WHO facilitates international collaboration in setting standards for clinical trial regist Lancet 365:1829-1831, 2015</li>
                            <li>Viergever RF, Li K: Trends in global clinical trial registration: An analysis of numbers of registered clinical trials in different parts of the world from 2004 to BMJ Open 5:e008932, 2015</li>
                            <li>Haeusler JMC: Certiﬁcation in good clinical practice and clinical trial quality: A retrospective analysis of protocol adherence in four multicenter trials in the Clin Res Regul Aff 26:20-23, 2009</li>
                            <li>Morgan C: Clinical trials: Collaboration is the secret to successful <a rel="noreferrer" target="_blank"  href="http://www.pharmaphorum.com/r-d/views-analysis-r-d/clinical-trials-collaboration-">www.pharmaphorum.com/r-d/views-analysis-r-d/clinical-trials-collaboration-</a> secret-successful-clinical-outsourcing</li>
                            <li>ClinicalTrials.gov: Study Map&ndash;Africa. https://clinicaltrials.gov/ct2/search/map?map=AF</li>
                            <li>Association of Clinical Research Organizations. <a rel="noreferrer" target="_blank"  href="http://www.acrohealth.org/">acrohealth.org</a></li>
                            <li>Morgan C: CRO-sponsor partnerships: 10 tips for enabling better CRO and sponsor <a rel="noreferrer" target="_blank"  href="http://www.contractpharma.com/issues/2016-10-01/view_">https://www.contractpharma.com/issues/2016-10-01/vi</a>ew_ features/cro-sponsor-partnerships</li>
                            </ol>
                        </div>
                        
                    </div>
                    <div className="col s12 l4 p-0 center">
                        <Link to="/contact-us" className="btn btn-small waves-effect waves-light hide-on-small-only contact-us-btn" style={{width: '100%'}}>Contact Us</Link>
                        <br />
                        <br />
                        <FacebookBadge
                            src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fsavanteconsultingofficial%2F&amp;tabs=timeline&amp;width=340&amp;height=500&amp;small_header=false&amp;adapt_container_width=true&amp;hide_cover=false&amp;show_facepile=true&amp;appId"
                        />
                        <br />
                        <iframe 
                            title="Google Badge"
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3964.5355607532397!2d3.387330214770342!3d6.45361289533113!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x103b8b568f3f5fc1%3A0xb2c59461ac52d3b7!2sSavante%20Consulting%20Limited!5e0!3m2!1sen!2sru!4v1615677592874!5m2!1sen!2sru"
                            allowFullScreen=""
                            width="340"
                            height="300" 
                            frameBorder="0">

                        </iframe>
                    </div>
                </div>
            </div>
            </article>
        </Layout>
    );
}